/* global $, MatgenGlobal, API_ENV, TENANT_ENV, Buffer, Promise */

import { API, Amplify, Auth, Storage } from 'aws-amplify';
import { v4 as UUID } from 'uuid';
import { contactSupport } from '../../-components/data/common-content.js';
import { loadQuestionnaire } from '../../audience-page/data.js';
import { AmplifyConfig, init } from '../config.js';
import {
  authUI,
  killModal,
  requireAuthModal,
  studySelectContentLoading,
} from '../display.js';
import { mainContentLoader, mainContentLoaderStop } from '../display.js';
import DOMPurify from 'dompurify';

export const faq_content = {
  English: [
    "What's being tested?",
    'What are the next steps if I choose to participate in the study?',
    'How long will I be in the study?',
    'Are there risks to being in the study?',
    'How will this affect my everyday life?',
    'Are there any costs to participate?',
    'What does it mean to be in a randomized study?',
    'If I sign up for the study, can I change my mind later?',
  ],
  Spanish: [
    '¿Qué se está probando?',
    '¿Cuáles son los próximos pasos si yo decido participar en el estudio?',
    '¿Por cuánto tiempo voy a estar en este estudio?',
    '¿Hay riesgos al ser parte del estudio?',
    '¿Cómo va a afectar mi vida diaria?',
    '¿Hay algún costo al ser participante?',
    '¿Qué significa estar en un estudio aleatorio?',
    'Si decido ser parte del estudio, ¿puedo cambiar de opinión más adelante?',
  ],
  Chinese: [
    '研究正在测试什么？',
    '如果我选择参与研究，接下来的步骤是什么？',
    '在此项调查中，我的参与时间有多久?',
    '参与研究有风险吗？',
    '这将如何影响我的日常生活？',
    '参与有任何费用吗？',
    '参与随机研究意味着什么？',
    '如果我报名参加研究，我以后可以改变主意吗？',
  ],
  Hindi: [
    'किस चीज़ का परीक्षण किया जा रहा है?',
    'अगर मैं हिस्सा लेना चुनुं तो अगले कदम क्या हैं?',
    'मैं इस अध्ययन में कब तक रहूंगा/गी?',
    'क्या अध्ययन में हिस्सा लेने के जोखिम हैं?',
    'इससे मेरा रोज़ाना का जीवन कैसे प्रभावित होगा?',
    'क्या हिस्सा लेने की कोई लागत है?',
    'किसी बिना निर्धारित क्रम (रैंडमाइज़्ड) अध्ययन में हिस्सा लेने का मतलब क्या है?',
    'अगर मैं अध्ययन के लिए साइन अप करूँ, तो क्या मैं बाद में अपना मन बदल सकता/सकती हूँ?',
  ],
  Tagalog: [
    'Ano ang sinusuri?',
    'Ano-ano ang susunod na hakbang kung sasali ako sa pag-aaral?',
    'Gaano ako katagal sa pag-aaral na ito?',
    'Ano-ano ang panganib ng pagsali sa pag-aaral?',
    'Paano nito maaapektuhan ang pang-araw-araw kong pamumuhay?',
    'May babayaran ba sa pagsali?',
    'Anong ibig sabihin ng pagsali sa isang randomized na pag-aaral?',
    'Kung nakapagpalista na ako sa pag-aaral, puwede pa ba akong magbago ng pasiya?',
  ],
};

export const clearFormErrors = (el) => {
  $(el)
    .closest('.blue-box')
    .css('border', 'none')
    .removeClass('matgen-form-error');
  $(el).removeClass('matgen-form-error');
  $(el).parent().removeClass('matgen-form-error');
  $(el).parent().find('.char-limit').removeClass('matgen-form-error');
  $(el).parent().find('.form-label').removeClass('matgen-form-error');

  $(el).parent().parent().removeClass('matgen-form-error');
  $(el)
    .parent()
    .parent()
    .parent()
    .find('.char-limit')
    .removeClass('matgen-form-error');
  $(el)
    .parent()
    .parent()
    .parent()
    .find('.form-label')
    .removeClass('matgen-form-error');
};

export const getVal = (find, type) => {
  return find ? find[type] : false;
};

export const getMicrositeAnswer = (id) => {
  return getVal(
    MatgenGlobal.currentStudy.answers.find((a) => a.question_id === id),
    'answer',
  );
};
export const tryJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return false;
  }
};

export const sanitizeHTML = (str) => {
  if (str && typeof str === 'string') {
    try {
      return DOMPurify.sanitize(str);
    } catch (e) {
      console.error(e);
      return '';
    }
    //return str.replace(/[^\w. ]/gi, (c) => {
    //  return `&#${c.charCodeAt(0)};`;
    //});
  } else {
    return '';
  }
};

export const getMicrositeVars = (version = 1) => {
  let hero_answer = tryJSON(
    getMicrositeAnswer('498e0def-e28e-48ce-95c4-77d7e5553cf9'),
  );
  if (!hero_answer) {
    hero_answer = [];
  }

  let logo_answer = tryJSON(
    getMicrositeAnswer('9a3e2212-632e-41ad-96cc-bc76c4231c70'),
  );
  if (logo_answer) {
    logo_answer = logo_answer[0];
  }

  let name_answer = tryJSON(
    getMicrositeAnswer('b8cd8b13-d00f-4257-b1a9-41f4675e41bb'),
  );
  if (name_answer) {
    name_answer = name_answer[0];
  }

  let welcome_answer = tryJSON(
    getMicrositeAnswer('cc8ad9ff-0a19-489c-954f-818ac1bad220'),
  );
  if (welcome_answer && Array.isArray(welcome_answer)) {
    welcome_answer = welcome_answer[0];
  }
  welcome_answer = { userAnswer: welcome_answer.html };

  let why_answer = tryJSON(
    getMicrositeAnswer('6f78efb0-de27-44e9-a750-c9a557ae5f1c'),
  );
  if (why_answer && Array.isArray(why_answer)) {
    why_answer = why_answer[0];
  }
  why_answer = { userAnswer: why_answer.html };

  let many_answer = tryJSON(
    getMicrositeAnswer('e6eaf634-b6c7-4782-82ae-b8e27fcab3fa'),
  );
  if (many_answer && Array.isArray(many_answer)) {
    many_answer = many_answer[0];
  }
  many_answer = { userAnswer: many_answer.html };

  let benefits_answer = tryJSON(
    getMicrositeAnswer('900f8c49-b2c9-4d50-b1a5-c1535d734815'),
  );
  if (benefits_answer && Array.isArray(benefits_answer)) {
    benefits_answer = benefits_answer[0];
  }
  benefits_answer = { userAnswer: benefits_answer.html };

  let happens_answer = tryJSON(
    getMicrositeAnswer('42d28066-b512-4aa8-ba56-860133909619'),
  );
  if (happens_answer && Array.isArray(happens_answer)) {
    happens_answer = happens_answer[0];
  }
  happens_answer = { userAnswer: happens_answer.html };

  let who_answer = tryJSON(
    getMicrositeAnswer('484fd598-2c6c-4476-9818-0535909d323f'),
  );
  if (who_answer && Array.isArray(who_answer)) {
    who_answer = who_answer[0];
  }
  who_answer = { userAnswer: who_answer.html };

  let goal_answer = tryJSON(
    getMicrositeAnswer('dd4a6415-3688-4ff5-a65a-c502a27b1fa9'),
  );
  if (goal_answer && Array.isArray(goal_answer)) {
    goal_answer = goal_answer[0];
  }
  goal_answer = { userAnswer: goal_answer.html };

  let language_answer = tryJSON(
    getMicrositeAnswer('945d40e7-2c59-4b9a-9dc8-d14d7dcdd648'),
  );
  if (!language_answer) {
    language_answer = 'English';
  }

  let phase_answer = tryJSON(
    getMicrositeAnswer('072d8175-47eb-41b7-a76b-a9415abb0949'),
  );
  if (!phase_answer) {
    phase_answer = [];
  }

  let gender_answer = tryJSON(
    getMicrositeAnswer('bf81ad48-b57a-47d1-b5a6-2a6ddf0a5e04'),
  );
  if (!gender_answer) {
    gender_answer = [];
  }

  let health_answer = tryJSON(
    getMicrositeAnswer('2f63c960-5e6a-4597-90b7-843073e4bea9'),
  );
  if (!health_answer) {
    health_answer = [];
  }

  let investigators_answer = tryJSON(
    getMicrositeAnswer('b54ca956-43d4-46db-8451-8f66256e3432'),
  );
  if (!investigators_answer) {
    investigators_answer = [];
  }

  let title_answer = tryJSON(
    getMicrositeAnswer('4254468c-9221-4f26-8a3c-50fd33aaf33c'),
  );
  if (!title_answer) {
    title_answer = [];
  }
  if (title_answer && Array.isArray(title_answer)) {
    title_answer = title_answer.find((a) => a.userAnswer === true);
  }

  let contact_answer = tryJSON(
    getMicrositeAnswer('89b5c840-2638-459a-bf9f-c36c0c61b3a5'),
  );
  if (!contact_answer) {
    contact_answer = [];
  }

  const locations = {};
  const location_question = tryJSON(
    getMicrositeAnswer('c173b8de-a88f-4b94-8233-d33e4e686182'),
  );
  if (location_question) {
    for (let i = 0; i < location_question.length; i++) {
      const state = getVal(
        location_question[i].answers.find((l) => {
          const obj = tryJSON(l.text);
          return obj.type === 'standard-select-single';
        }),
        'userAnswer',
      );

      if (!locations[state]) {
        locations[state] = [];
      }
      locations[state].push(
        location_question[i].answers.map((a) => {
          a.userAnswer = sanitizeHTML(a.userAnswer);
          return a;
        }),
      );
    }
  }

  const faq = tryJSON(
    getMicrositeAnswer('99120986-a88a-46c1-ae87-2af83030740e'),
  );
  if (faq) {
    faq.sort((a, b) => a.sort_order - b.sort_order);
  }

  //MatgenGlobal.mstemp = microsite;

  const hero_image = sanitizeHTML(
    getVal(
      tryJSON(
        getVal(
          hero_answer.find((a) => {
            return a.userAnswer === true;
          }),
          'text',
        ),
      ),
      'src',
    ),
  );
  const hero_dir = sanitizeHTML(
    getVal(
      tryJSON(
        getVal(
          hero_answer.find((a) => {
            return a.userAnswer === true;
          }),
          'text',
        ),
      ),
      'dir',
    ),
  );
  const logo = sanitizeHTML(getVal(logo_answer, 'userAnswer'));

  return {
    updated: false,
    base_url:
      'https://m4c-matgen-microsites-new.s3.amazonaws.com/nia/template-001/',
    template_version: version,
    study_language:
      language_answer != 'English'
        ? sanitizeHTML(
            getVal(
              language_answer.find((a) => a.userAnswer === true),
              'text',
            ),
          )
        : 'English',
    study_name: sanitizeHTML(getVal(name_answer, 'userAnswer'))
      ? sanitizeHTML(getVal(name_answer, 'userAnswer'))
      : '',
    welcome: sanitizeHTML(getVal(welcome_answer, 'userAnswer'))
      ? sanitizeHTML(getVal(welcome_answer, 'userAnswer'))
      : '',
    phase_text: sanitizeHTML(
      getVal(
        phase_answer.find((a) => a.userAnswer === true),
        'text',
      ),
    ),
    phase_sub_text: sanitizeHTML(
      getVal(
        phase_answer.find((a) => a.userAnswer === true),
        'sub_text',
      ),
    ),
    study_goal: sanitizeHTML(getVal(goal_answer, 'userAnswer')),
    min_age: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('2626b183-1ca5-4fb6-a5f0-a81f214369a3'))[0],
        'userAnswer',
      )[0],
    ),
    max_age: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('2626b183-1ca5-4fb6-a5f0-a81f214369a3'))[0],
        'userAnswer',
      )[1],
    ),
    gender: gender_answer
      .filter((a) => a.userAnswer)
      .map((a) => sanitizeHTML(a.text)),
    health_status: health_answer
      .filter((a) => a.userAnswer)
      .map((a) => sanitizeHTML(a.text)),
    conditions: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('4d88a934-f562-49d8-8d08-4c956a5ed012'))[0],
        'userAnswer',
      ),
    ),
    requirements: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('484fd598-2c6c-4476-9818-0535909d323f'))[0],
        'userAnswer',
      ),
    ),
    /*what_happens: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('42d28066-b512-4aa8-ba56-860133909619'))[0],
        'userAnswer'
      )
    ),*/
    /*how_long: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('6f78efb0-de27-44e9-a750-c9a557ae5f1c'))[0],
        'userAnswer'
      )
    ),*/
    how_long: sanitizeHTML(getVal(who_answer, 'userAnswer')),
    /*who_with: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('e6eaf634-b6c7-4782-82ae-b8e27fcab3fa'))[0],
        'userAnswer'
      )
    ),*/
    who_with: sanitizeHTML(getVal(many_answer, 'userAnswer')),
    benefits_risks: sanitizeHTML(getVal(benefits_answer, 'userAnswer')),
    what_happens: sanitizeHTML(getVal(happens_answer, 'userAnswer')),
    provided: sanitizeHTML(getVal(why_answer, 'userAnswer')),
    funding: sanitizeHTML(
      getVal(
        tryJSON(getMicrositeAnswer('0093e24d-d016-424a-9e68-d5d93d65da03'))[0],
        'userAnswer',
      ),
    ),
    investigators: investigators_answer.map((a) => {
      return a.answers.map((ua) => {
        return {
          name: sanitizeHTML(JSON.parse(ua.text).label),
          value: sanitizeHTML(ua.userAnswer),
        };
      });
    }),
    primary_contact: contact_answer.map((a) => sanitizeHTML(a.userAnswer)),
    locations,
    faq,
    hero_image,
    hero_dir,
    logo,
    website_title: title_answer ? sanitizeHTML(title_answer.text) : '',
    website_subtitle: title_answer ? sanitizeHTML(title_answer.sub_text) : '',
  };
};

export const formatTimer = (ms) => {
  const milliseconds = ms % 1000;
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (60 * 1000)) % 60);
  const hours = Math.floor((ms / (3600 * 1000)) % 3600);
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}:${milliseconds}`;
};

export const translateAnswerTags = async (tagsIn = false) => {
  await loadQuestionnaire(MatgenGlobal.questionnaire_id);
  const tags = [];
  const rawTags = tagsIn || MatgenGlobal.answerTags;
  if (rawTags) {
    for (let i = 0; i < rawTags.length; i++) {
      const q = MatgenGlobal.questions.find(
        (q) => q.id === rawTags[i].question_id,
      );

      if (q) {
        const a = q.answers.find((a) => a.id === rawTags[i].answer_id);
        if (a) {
          tags.push({
            type: q.section_type,
            q: q.text,
            a: a.text,
          });
        }
      }
    }
  }
  return tags;
};

export const isJsonAnswer = (component) => {
  switch (component) {
    default:
      throw new Error(`Invalid component type: ${component}`);
    case 'date-range':
    case 'img-select-single':
    case 'list-faq':
    case 'list-multi-input':
    case 'multi-input':
    case 'range':
    case 'text':
    case 'textarea':
    case 'richtext':
    case 'upload-single':
      return true;
    case 'select-multiple':
    case 'select-single':
    case 'card-select-single':
    case 'box-select-single':
    case 'list-select-single':
      return false;
  }
};

export const processStudyForm = async () => {
  if ($('#study-name').val() === '') {
    MatgenGlobal.UI.handleError(
      'folder name missing',
      '<o>You must enter a folder name to create a folder.</p>',
    );
    $('#study-name').addClass('error');
    $('#study-name').prev().addClass('error');
    return false;
  }
  const newId = UUID();
  const study = {
    id: newId,
    name: $('#study-name').val(),
  };
  if ($('#study-id').val()) {
    study.data = JSON.stringify({
      study_id: $('#study-id').val()
        ? $('#study-id').val()
        : '00000000-0000-0000-0000-000000000000',
    });
  }
  studySelectContentLoading();
  const user = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
  study.user_name = user.identityId;
  try {
    await MatgenGlobal.Data.API.request(`/user-folders`, 'POST', study);
  } catch (e) {
    console.error(e);
    MatgenGlobal.UI.handleError(
      'Error',
      `There was an error saving your study info. Please try again later or ${contactSupport}.`,
    );
  }
  return newId;
};

export const getQueryParam = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

const pushQuery = (arr, name, value) => {
  arr.push({
    name,
    value,
  });
};

export const buildQueryString = ({
  tags,
  page,
  template_id,
  grant_number,
  material_id,
  study_id,
  page_id,
  opener,
  theme_color,
} = {}) => {
  if (typeof page === 'undefined') {
    page = MatgenGlobal.getQueryParam('page');
  }
  const vars = [];
  if (tags) {
    const base64Data = new Buffer.from(JSON.stringify(tags));
    pushQuery(vars, 'tags', encodeURIComponent(base64Data.toString('base64')));
  }
  if (page) {
    pushQuery(vars, 'page', page);
  }
  if (template_id) {
    pushQuery(vars, 'template_id', template_id);
  }
  if (material_id) {
    pushQuery(vars, 'material_id', material_id);
  }
  if (study_id) {
    pushQuery(vars, 'study_id', study_id);
  }
  if (page_id) {
    pushQuery(vars, 'page_id', page_id);
  }
  if (opener) {
    pushQuery(vars, 'opener', opener);
  }
  if (theme_color) {
    pushQuery(vars, 'theme_color', theme_color);
  }

  if (grant_number) {
    pushQuery(vars, 'grant_number', grant_number);
  }

  const query = vars.map((v) => `${v.name}=${v.value}`).join('&');
  const url = `${query.length > 0 ? `?${query}` : ''}`;
  return url;
};

export const alertModal = (
  title,
  content,
  classes = '',
  modalId = 'alert-modal',
  buttons = [],
) => {
  MatgenGlobal.M4CModal.show({
    id: modalId,
    title,
    header: true,
    footer: false,
    content: `
      <div class="alert-modal-content-wrapper">
        ${content}
      </div>
    `,
    buttons,
    classes,
  });
};

export const emit = ({
  event,
  detail = null,
  target = document,
  bubbles = true,
}) => {
  //console.error('EMIT:', event, MatgenGlobal.userInitialized);
  if (event && target) {
    target.dispatchEvent(
      new CustomEvent(event, {
        composed: true,
        detail,
        bubbles,
      }),
    );
  }
};

export const configure = async (overrides = {}) => {
  let api_env = 'dev';
  if (API_ENV === 'production') {
    api_env = 'prod';
  } else {
    api_env = API_ENV;
  }

  const coreTenant = TENANT_ENV ? TENANT_ENV : 'm4c';

  //console.error('ENV:', api_env, coreTenant);

  MatgenGlobal.Options = {
    env: api_env,
    tenant_env: coreTenant,
    containerId: 'matgen-scale-container',
    //editorTargetSelector: '#matgen-controller',
    controllerTargetSelector: '#nia-matgen-controller',
    sidebarTargetSelector: '#matgen-sidebar',
    rootPage: overrides.rootPage ? overrides.rootPage : 'generator.html',
    amplify: {
      Auth: {
        mandatorySignIn: false,
      },
    },
  };

  MatgenGlobal.Amplify = {
    Auth,
    Storage,
    API,
  };
  MatgenGlobal.AmplifyConfig = AmplifyConfig;

  mainContentLoader({ showMessage: true });
  $('#loader-message').text('Configuring application...');

  Amplify.configure(MatgenGlobal.AmplifyConfig);
  await init();

  //console.log('API_ENV:', API_ENV, 'TENANT_ENV:', TENANT_ENV);
  //console.log('AmplifyConfig', MatgenGlobal.AmplifyConfig);

  MatgenGlobal.NavDirection = 0;
  MatgenGlobal.SuppressSidebarActions = true;
  MatgenGlobal.liveContentSelector = '#main-whole';

  MatgenGlobal.loginModalSelector = '#matgen-signin-modal';

  //MatgenGlobal.Suppress508 = true;
  MatgenGlobal.FixedSidebar = true;
  MatgenGlobal.materialData = {};
  MatgenGlobal.useNamespace = false;
  /*MatgenGlobal.MICROSITE_BUCKET =
      API_ENV === 'production' ? 'fmg-nia-new' : 'fmg-nia-dev-new';*/
  MatgenGlobal.MICROSITE_BUCKET = 'm4c-matgen-microsites-new';
  MatgenGlobal.MICROSITE_DOMAIN = 'outreachpro.org';
  if (MatgenGlobal.generator) {
    MatgenGlobal.RootRoute = () => {
      MatgenGlobal.generator.step1();
    };
  }

  //MatgenGlobal.PageLoaderTarget = '#m4c-wrapper';

  MatgenGlobal.Tipsheet = {
    file: '/assets/tip-sheet_v1d.pdf',
    name: 'Social Media Tip Sheet',
  };

  MatgenGlobal.emit = emit;
  MatgenGlobal.UI.alertModal = alertModal;
  MatgenGlobal.QuestionnairePage = 'create/audience.html';
  MatgenGlobal.TemplatePickerPage = 'create/template-picker.html';
  MatgenGlobal.WebsiteDataPage = 'create/website-data.html';
  MatgenGlobal.WebsiteManagementPage = 'create/website-management.html';
  MatgenGlobal.EditorPage = 'create/editor.html';
  MatgenGlobal.MaterialSavedPage = 'create/material-saved.html';
  MatgenGlobal.MyMaterialsPage = 'my-materials.html';
  MatgenGlobal.StudyMaterialsPage = 'study-materials.html';
  MatgenGlobal.Openers = {
    my_materials: 'my-materials.html',
    study_materials: 'study-materials.html',
  };
};

export const authHeaderOpts = async () => {
  try {
    const session = await MatgenGlobal.Amplify.Auth.currentSession();
    const token = session.idToken.jwtToken;
    const opts = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return opts;
  } catch (e) {
    //console.error(e);
    return false;
  }
};

export const requireAuth = async () => {
  try {
    mainContentLoader({ showMessage: true });
    $('#loader-message').text('Checking authentication...');
    let user = false;
    try {
      user = MatgenGlobal.AuthUser
        ? await MatgenGlobal.Amplify.Auth.currentAuthenticatedUser()
        : false;
    } catch (e) {
      user = false;
    }
    if (!user) {
      requireAuthModal();
      //$('#study-prompt-modal').modal('show');
      return false;
    }
  } catch (e) {
    $('#study-prompt-modal').modal('show');
    return false;
  }
  mainContentLoaderStop();
  return true;
};

export const decodeAnswerTags = (tags) => {
  try {
    tags = decodeURIComponent(tags);
    const buff = new Buffer.from(tags, 'base64');
    tags = JSON.parse(buff.toString());
  } catch (e) {
    console.error(e);
    window.location.href = '/${MatgenGlobal.QuestionnairePage}';
  }
  return tags;
};

export const configureFormTags = async () => {
  if (!MatgenGlobal.FormTagsConfigured) {
    const websiteQuestions = await MatgenGlobal.Data.API.request(
      `/questions?type=material&tenant_id=${MatgenGlobal.tenant_id}`,
    );
    const locQ = await MatgenGlobal.Amplify.API.get(
      'public',
      `/questions?handle=locations`,
    );

    const pathQ = websiteQuestions.find((q) => q.handle === 'path');
    const pathQuestionId = pathQ.question_id;
    const distQ = websiteQuestions.find((q) => q.handle === 'dist');
    const distributionQuestionId = distQ.question_id;
    const langQ = websiteQuestions.find((q) => q.handle === 'language');
    const langQs = websiteQuestions.filter((q) => q.handle === 'language');
    const languageQuestionId = langQ.question_id;
    const ethQ = websiteQuestions.find((q) => q.handle === 'ethnicity');
    const ethnicityQuestionId = ethQ.question_id;
    const healthQ = websiteQuestions.find((q) => q.handle === 'health');
    const healthStatusQuestionId = healthQ.question_id;
    const locationsQuestionId = locQ[0].id;
    const locRes = await MatgenGlobal.Amplify.API.get(
      'public',
      `/answers?question_id=${locationsQuestionId}`,
    );
    const locA = locRes.find((l) => l.text.includes('Address Line 1'));
    const locationsAddressLine1QuestionId = locA.id;

    const studySpecificAnswerId = 'b03c4dfa-e4cb-49a5-8f8f-9a17b61be966';

    const pathTag = {
      question_id: pathQuestionId,
      answer_id: 'b03c4dfa-e4cb-49a5-8f8f-9a17b61be966',
      type: 'bool',
    };
    const clincicalStudiesTag = {
      question_id: pathQuestionId,
      answer_id: '2d425dfd-8467-41af-9c74-a20cea8145cb',
      type: 'bool',
    };
    const brainHealthTag = {
      question_id: pathQuestionId,
      answer_id: '78f2fda5-b8a8-4230-a4c8-05e9dfa1efae',
      type: 'bool',
    };

    MatgenGlobal.languageQuestion = langQs.map((q) => {
      return {
        text: q.answer_text,
        question_id: q.question_id,
        id: q.answer_id,
      };
    });
    MatgenGlobal.pathTag = pathTag;
    MatgenGlobal.clincicalStudiesTag = clincicalStudiesTag;
    MatgenGlobal.brainHealthTag = brainHealthTag;
    MatgenGlobal.pathQuestionId = pathQuestionId;
    MatgenGlobal.studySpecificAnswerId = studySpecificAnswerId;
    MatgenGlobal.distributionQuestionId = distributionQuestionId;
    MatgenGlobal.languageQuestionId = languageQuestionId;
    MatgenGlobal.ethnicityQuestionId = ethnicityQuestionId;
    MatgenGlobal.healthStatusQuestionId = healthStatusQuestionId;
    MatgenGlobal.locationsQuestionId = locationsQuestionId;
    MatgenGlobal.locationsAddressLine1QuestionId =
      locationsAddressLine1QuestionId;

    MatgenGlobal.questionnaireInitialized = true;
    MatgenGlobal.emit({
      event: 'matgen-event-questionnaire-initialized',
    });
    MatgenGlobal.FormTagsConfigured = true;
  }
};

export const loadAnswerTags = async () => {
  //console.error('LOAD ANSWER TAGS');
  if (!MatgenGlobal.AnswerTagsLoaded) {
    let tags = MatgenGlobal.getQueryParam('tags');
    const material_id = MatgenGlobal.getQueryParam('material_id');
    if (tags) {
      tags = decodeAnswerTags(tags);
      if (tags && tags !== 'null') {
        const swimlaneTag = tags.find(
          (t) => t.question_id === MatgenGlobal.pathQuestionId,
        );
        MatgenGlobal.swimlaneTag = swimlaneTag;
        if (location.href.includes('template-picker') && tags.length < 4) {
          window.location.href = `/${
            MatgenGlobal.QuestionnairePage
          }${MatgenGlobal.buildQueryString({
            tags,
            page: false,
          })}`;
        }
        MatgenGlobal.answerTags = tags;
      }
    } else if (material_id) {
      const material = await MatgenGlobal.Data.getMaterial(material_id);
      const template_tags = await MatgenGlobal.Data.getTemplateTags(
        material.template_id,
      );
      if (!material.tags) {
        const tags = await translateAnswerTags(template_tags);
        let pathTag = tags.filter((t) => t.type === 'goal');
        if (pathTag && Array.isArray(pathTag)) {
          pathTag = pathTag[0];
        }
        const untranslatedPathTag = template_tags.find(
          (t) => t.question_id === 'ba6ad823-e74d-42da-a378-af396d5faca8',
        );
        if (pathTag.a !== 'Materials Describing a Specific Study') {
          MatgenGlobal.notStudy = true;
        }
        MatgenGlobal.pathTag = untranslatedPathTag;
        $('#create-another').attr(
          'href',
          `/${MatgenGlobal.QuestionnairePage}${MatgenGlobal.buildQueryString({
            tags: [MatgenGlobal.pathTag],
          })}`,
        );
      } else {
        let matTags;
        try {
          matTags = JSON.parse(material.tags);
          const tags = await translateAnswerTags(matTags);
          let pathTag = tags.filter((t) => t.type === 'goal');
          if (pathTag && Array.isArray(pathTag)) {
            pathTag = pathTag[0];
          }
          const untranslatedPathTag = matTags.find(
            (t) => t.question_id === 'ba6ad823-e74d-42da-a378-af396d5faca8',
          );
          if (
            pathTag &&
            pathTag.a !== 'Materials Describing a Specific Study'
          ) {
            MatgenGlobal.notStudy = true;
          }
          MatgenGlobal.pathTag = untranslatedPathTag;
          $('#create-another').attr(
            'href',
            `/${MatgenGlobal.QuestionnairePage}${MatgenGlobal.buildQueryString({
              tags: [MatgenGlobal.pathTag],
            })}`,
          );
        } catch (e) {
          console.error('Bad tag JSON', e, material.tags);
        }
        MatgenGlobal.answerTags = matTags;
      }
    } else {
      MatgenGlobal.emit({
        event: 'matgen-event-error-no-tags',
      });
    }
    MatgenGlobal.emit({
      event: 'matgen-event-tags-loaded',
    });
    MatgenGlobal.AnswerTagsLoaded = true;
  }
};

export const createThumbnail = async (
  id,
  material_id,
  type = 'template',
  width = 300,
) => {
  //console.error('CREATING THUMB:', id, material_id, type);
  let srcURL;
  if (type !== 'material') {
    srcURL = MatgenGlobal.Data.getTemplateFileURL(
      `${id}`,
      MatgenGlobal.tenant_id,
      '.png',
    );
  } else {
    const credentials =
      await MatgenGlobal.Amplify.Auth.currentUserCredentials();
    srcURL = `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/protected/${credentials.identityId}/${material_id}/${id}.png`;
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute('crossorigin', 'anonymous');
    img.onload = async () => {
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d'),
        oc = document.createElement('canvas'),
        octx = oc.getContext('2d');

      document.body.appendChild(canvas);

      canvas.width = width;
      canvas.height = (canvas.width * img.height) / img.width;

      let cur = {
        width: Math.floor(img.width * 0.5),
        height: Math.floor(img.height * 0.5),
      };

      oc.width = cur.width;
      oc.height = cur.height;

      octx.drawImage(img, 0, 0, cur.width, cur.height);

      while (cur.width * 0.5 > width) {
        cur = {
          width: Math.floor(cur.width * 0.5),
          height: Math.floor(cur.height * 0.5),
        };
        octx.drawImage(
          oc,
          0,
          0,
          cur.width * 2,
          cur.height * 2,
          0,
          0,
          cur.width,
          cur.height,
        );
      }

      ctx.drawImage(
        oc,
        0,
        0,
        cur.width,
        cur.height,
        0,
        0,
        canvas.width,
        canvas.height,
      );

      const durl = canvas.toDataURL('image/jpeg', 0.7);
      if (type === 'material') {
        const base64Data = new Buffer.from(
          durl.replace(/^data:image\/\w+;base64,/, ''),
          'base64',
        );
        await MatgenGlobal.Data.saveMaterialImage(
          base64Data,
          material_id,
          `${id}-thumbnail`,
        );
      } else {
        try {
          await MatgenGlobal.Data.saveTemplateFile(
            `${id}-thumbnail.jpg`,
            MatgenGlobal.tenant_id,
            durl,
          );
        } catch (e) {
          console.error(e);
        }
      }
      canvas.remove();
      resolve(srcURL);
    };

    img.src = srcURL;
  });
};

const getFileThumbURL = (template) => {
  if (template.type === 'VIDEO') {
    return '/assets/img/icon-video.svg';
  } else if (template.preview_type === 'IMAGE') {
    return `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/tenant/${template.tenant_id}/templates/${template.id}${template.preview_image_ext}`;
  } else {
    return '/assets/img/file-lines-regular.svg';
  }
  //thumbnail = `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/tenant/${template.tenant_id}/templates/${template.id}-thumbnail.png`;
};

export const getTemplateThumbUrl = async (
  template,
  pages = [],
  pageNumber = 0,
) => {
  let thumbnail = false;
  if (template.type === 'WEBSITE') {
    thumbnail = '/assets/img/microsite-1-thumb.png';
  } else if (template.type === 'FILE' || template.type === 'VIDEO') {
    thumbnail = getFileThumbURL(template);
  } else {
    if (pages.length !== 0) {
      thumbnail = MatgenGlobal.Data.getTemplateFileURL(
        pages[pageNumber].id,
        template.tenant_id,
        '.png',
      );
    } else {
      thumbnail = MatgenGlobal.Data.getTemplateFileURL(
        `${template.page_1_id ? template.page_1_id : template.id}`,
        template.tenant_id,
        '.png',
      );
      /*
      if (MatgenGlobal.createThumbs) {
        thumbnail = await createThumbnail(
          template.page_1_id ? template.page_1_id : template.id
        );
      }
      */
    }
  }
  return `${thumbnail}?cache_buster=${new Date().getTime()}`;
};

export const getMaterialThumbUrl = ({
  material,
  template,
  pages = [],
  pageNumber = 0,
  credentials,
  pageId = false,
} = {}) => {
  let thumbnail = false;
  if (template.type === 'WEBSITE') {
    thumbnail = '/assets/img/microsite-1-thumb.png';
  } else if (template.type === 'FILE' || template.type === 'VIDEO') {
    thumbnail = getFileThumbURL(template);
  } else {
    if (pages.length !== 0) {
      thumbnail = `https://${
        MatgenGlobal.AmplifyConfig.Storage.bucket
      }.s3.amazonaws.com/protected/${credentials.identityId}/${material.id}/${
        pageId ? pageId : pages[pageNumber].id
      }-thumbnail.png`;
    } else {
      thumbnail = `https://${
        MatgenGlobal.AmplifyConfig.Storage.bucket
      }.s3.amazonaws.com/protected/${credentials.identityId}/${material.id}/${
        pageId ? pageId : pages[0].id
      }-thumbnail.png`;
    }

    /*try {
      await $.ajax({
        url: thumbnail,
        type: 'HEAD',
        error: async () => {
          $('#loader-message').text('Creating material thumbnail...');
          thumbnail = createThumbnail(pages[0].id, material.id, 'material');
        },
      });
    } catch (e) {
      console.error(e);
    }*/
  }
  return thumbnail;
};

export const authCallback = async () => {
  const tenant = MatgenGlobal.AuthUser.user.attributes['custom:tenant'];
  //console.error('AUTH CALLBACK', MatgenGlobal.AuthUser.user, tenant);
  //console.log(MatgenGlobal.AuthUser.user);
  if (!tenant || tenant !== 'nia') {
    MatgenGlobal.UI.handleError(
      'Authentication Error',
      `<p>There was an error authenticating your user. Please try again or ${contactSupport}.<p>`,
    );
    await MatgenGlobal.Amplify.Auth.signOut({ global: false });
    authUI();
    return false;
  }

  if (MatgenGlobal.AuthUser.getUserRole() === 'admin') {
    if (window.location.pathname !== 'admin/templates.html') {
      window.location.href = 'admin/templates.html';
    }
  }

  const opts = await authHeaderOpts();

  const defaultFolder = await MatgenGlobal.Amplify.API.get(
    'authenticated',
    `/user-folders/00000000-0000-0000-0000-000000000000`,
    opts,
  );

  if (!defaultFolder || defaultFolder.length === 0) {
    const user = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
    try {
      await MatgenGlobal.Data.API.request(`/user-folders`, 'POST', {
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Default',
        user_name: user.identityId,
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'Error',
        `There was an error creating default folder. Please try again later or ${contactSupport}.`,
      );
    }
  }

  if (MatgenGlobal.clickAfterLogin) {
    $(`#${MatgenGlobal.clickAfterLogin}`).trigger('click');
    MatgenGlobal.clickAfterLogin = null;
  }

  killModal('#matgen-signin-modal');
  if (window.location.pathname === '/my-materials.html') {
    window.location.reload();
  }
  authUI();
};
