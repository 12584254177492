/* global $ */

class Panel {
  constructor({
    prefix,
    targetClasses,
    innerClasses,
    initialContent,
    loadInitial = true,
    startLoader = true,
    loadingMessage = 'Loading',
  } = {}) {
    this.targetSelector = `#${prefix}-wrapper`;
    this.container = `
    <div id="${prefix}-inner"${innerClasses ? ` class="${innerClasses}"` : ''}>
      ${initialContent ? initialContent : ''}
      <div id="${prefix}"${targetClasses ? ` class="${targetClasses}"` : ''}>
      </div>
    </div>
  `;
    this.prefix = prefix;
    this.loadInitial = loadInitial;
    this.startLoader = startLoader;
    this.loadingMessage = loadingMessage;
  }

  init() {
    $(this.targetSelector).append($(this.container)).addClass('_panel');
    if (this.initFunction && typeof this.initFunction == 'function') {
      this.initFunction(this);
    }
    if (
      this.startLoader &&
      this.loader &&
      this.loader.start &&
      typeof this.loader.start === 'function'
    ) {
      this.loader.start(true);
      $('#loader-message').text(`${this.loadingMessage}...`);
    }
    if (this.loadInitial && this.load && typeof this.load === 'function') {
      this.load();
    }
  }

  async loadPanel(args) {
    if (
      this.loader &&
      this.loader.start &&
      typeof this.loader.start === 'function'
    ) {
      this.loader.start();
    }
    if (this.load && typeof this.load === 'function') {
      try {
        const data = await this.load(args);
        this.data = data;
      } catch (e) {
        if (this.error && typeof this.error === 'function') {
          this.error(e);
        } else {
          throw new Error('Panel load error');
        }
      }
    } else {
      console.log('Missing panel load function', 'SCOPE:', this);
    }
    this.displayPanel();
    if (this.initFunction && typeof this.initFunction === 'function') {
      this.initFunction({ data: this.data });
    }
    if (
      this.loader &&
      this.loader.stop &&
      typeof this.loader.stop === 'function'
    ) {
      this.loader.stop();
    }
  }

  displayPanel({ data } = {}) {
    if (!data || data.length === 0) {
      if (this.noData && typeof this.noData === 'function') {
        this.noData({ data });
      } else if (this.error && typeof this.error === 'function') {
        this.error({ data });
      }
    } else if (this.display && typeof this.display === 'function') {
      this.display({ data });
    } else if (this.error && typeof this.error === 'function') {
      data.message = 'Missing panel display function';
      this.error({ data });
    } else {
      throw new Error('Panel display failure');
    }
  }

  alertDiv({ message, type = 'danger' }) {
    return `
      <div class="alert-wrapper results-${type}">
        <div class="alert alert-${type}" role="alert">
          ${message}
        </div>
      </div>
    `;
  }
}

export default Panel;
