/* global MatgenGlobal, $ */

export const getQuestionnaire = async (qid) => {
  let local = sessionStorage.getItem('matgen-questionnaire');
  if (local) {
    try {
      local = JSON.parse(local);
    } catch (e) {
      console.error(e);
      local = false;
    }
  }
  if (local) {
    MatgenGlobal.questionnaire = local;
  } else {
    MatgenGlobal.questionnaire = await MatgenGlobal.Data.getQuestionnaire(qid);
    sessionStorage.setItem(
      'matgen-questionnaire',
      JSON.stringify(MatgenGlobal.questionnaire),
    );
  }
};

export const loadQuestionnaire = async (qid) => {
  await getQuestionnaire(qid);
  const sectionKeys = Object.keys(MatgenGlobal.questionnaire).filter(
    (k) => k !== 'id' && k !== 'materials',
  );
  const sections = sectionKeys.map((k) => {
    return {
      section_type: k,
      ...MatgenGlobal.questionnaire[k],
    };
  });
  const questions = [];
  const answers = [];
  for (let i = 0; i < sections.length; i++) {
    if (!sections[i].questions) {
      throw new Error('Section has no questions');
    }
    for (let j = 0; j < sections[i].questions.length; j++) {
      if (!sections[i].questions[j].answers) {
        throw new Error('Question has no answers');
      }
      if (MatgenGlobal.priorities[sections[i].questions[j].handle] >= 0) {
        questions.push({
          section_type: sections[i].section_type,
          ...sections[i].questions[j],
        });

        for (let k = 0; k < sections[i].questions[j].answers.length; k++) {
          answers.push({
            question_priority:
              MatgenGlobal.priorities[sections[i].questions[j].handle],
            section_type: sections[i].section_type,
            section_name: sections[i].name,
            section_sort_order: sections[i].sort_order,
            question_id: sections[i].questions[j].id,
            question_sort_order: sections[i].questions[j].sort_order,
            ...sections[i].questions[j].answers[k],
          });
        }
      } else if (
        MatgenGlobal.priorities[sections[i].questions[j].handle] !== -1
      ) {
        console.error(
          `Missing priority for ${sections[i].questions[j].handle} : ${sections[i].questions[j].text}`,
        );
      }
    }
  }
  MatgenGlobal.questions = questions;
  MatgenGlobal.answers = answers;
};

const getAnswers = ({ selector, questionId, error } = {}) => {
  if (!questionId) {
    throw new Error('Missing question ID');
  }
  if ($(`${selector} .selected`).length === 0) {
    $(selector).addClass('bg-danger');
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(selector).prev('h4').offset().top,
      },
      25,
    );
    MatgenGlobal.UI.handleError(error.title, error.text);
    return false;
  } else {
    $(`${selector} .selected`).each((i, el) => {
      MatgenGlobal.answerTags.push({
        question_id: $(el).attr('data-question-id'),
        answer_id: $(el).attr('data-answer-id'),
        type: 'bool',
      });
    });
    return true;
  }
};

export const getAudienceSelections = () => {
  MatgenGlobal.answerTags = [MatgenGlobal.swimlaneTag];
  $('.distribution-types-wrapper').removeClass('bg-danger');
  $('#health-statuses').removeClass('bg-danger');

  if ($('.distribution-question.option-card.selected').length === 0) {
    $('.distribution-types-wrapper').addClass('bg-danger');
    MatgenGlobal.UI.handleError(
      'Missing Distribution Type Selection',
      'You must select a distribution type for your material.',
    );
  }

  const distributionAnswerId = $(
    '.distribution-question.option-card.selected',
  ).attr('data-answer-id');
  MatgenGlobal.answerTags.push({
    question_id: MatgenGlobal.distributionQuestionId,
    answer_id: distributionAnswerId,
    type: 'bool',
  });

  if (distributionAnswerId !== 'website') {
    const health = getAnswers({
      selector: '#health-statuses',
      questionId: MatgenGlobal.healthStatusQuestionId,
      error: {
        title: 'Missing Health Status Selection',
        text: 'You must select one or more health statuses for your material.',
      },
    });
    if (health) {
      getAnswers({
        selector: '#ethnicities',
        questionId: MatgenGlobal.ethnicityQuestionId,
        error: {
          title: 'Missing Ethnicity Selection',
          text: 'You must select one or more ethnicities for your material.',
        },
      });
    }
  }
};
