/* global $ */

const LOADER_GLOBAL_TIMEOUT = 400;

class SectionLoader {
  constructor({ prefix, loaderAnimationMarkup, skeleton } = {}) {
    this.prefix = prefix;
    this.transitionTargetSelector = `#${prefix}-inner`;
    this.loaderTargetSelector = `#${prefix}-wrapper`;
    this.loaderId = `${prefix}-loader`;
    this.loaderAnimationMarkup = loaderAnimationMarkup;
    this.skeleton = skeleton;
  }

  static displayLoader({
    transitionTargetSelector,
    loaderTargetSelector,
    loaderId,
    loaderAnimationMarkup,
    showMessage = false,
  } = {}) {
    $(transitionTargetSelector).css(
      'transition',
      'grayscale 2s, brightness 2s, blur 2s',
    );
    $(transitionTargetSelector).css(
      'filter',
      'grayscale(100%) brightness(1.12) blur(3px)',
    );
    $(loaderTargetSelector).append(
      $(`
        <div id="${loaderId}" class="section-loader">
          ${loaderAnimationMarkup}
          ${
            showMessage
              ? `<div id="loader-message" class="badge rounded-pill">Loading...</div>`
              : ''
          }
        </div>
    `),
    );
  }
  start(showMessage = false) {
    if (this.started) {
      return false;
    }
    //console.error('START SECTION LOADER', this);
    this.started = true;
    SectionLoader.displayLoader({
      transitionTargetSelector: this.transitionTargetSelector,
      loaderTargetSelector: this.loaderTargetSelector,
      loaderId: this.loaderId,
      loaderAnimationMarkup: this.loaderAnimationMarkup,
      showMessage,
    });
    if (this.skeleton && typeof this.skeleton === 'function') {
      this.skeleton(this.transitionTargetSelector);
    }
  }
  stop(scrollTo = false) {
    if (!this.started) {
      return false;
    }
    //console.error('STOP LOADER', this);
    this.started = false;
    window.setTimeout(() => {
      $(`#${this.loaderId}`).remove();
      //$(`${this.transitionTargetSelector} .skeleton-loader-container`).remove();
      $(this.transitionTargetSelector).css('transition', 'initial');
      $(this.transitionTargetSelector).css('filter', 'initial');
      if (scrollTo !== false) {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: parseFloat(scrollTo),
          },
          25,
        );
      }
    }, LOADER_GLOBAL_TIMEOUT);
  }
  static blockLoaderAnimation() {
    return '<div class="loader-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
  }
  static dotsLoaderAnimation() {
    return '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>';
  }

  static loadGhostCards(targetSelector) {
    $(targetSelector).empty();
    for (let i = 0; i < 6; i++) {
      $(targetSelector).append($(SectionLoader.ghostCard()));
    }
  }
  static ghostCard() {
    return `
        <div class="distribution-question option-card normal-card ghost-card" tabindex="0">
          <div class="option-card__graphic">
            <div class="option-card__icon">
            </div>
          </div>
          <div style="width:100%;height:100%;">
            <div class="option-card__copy"></div>
            <div class="option-card__copy"></div>
          </div>
        </div>
      `;
  }
  static loadGhostOptions(wrapperTargetSelector, containerTargetSelector) {
    $(wrapperTargetSelector).show();
    $(containerTargetSelector).empty();
    for (let i = 0; i < 6; i++) {
      $(containerTargetSelector).append(
        $(`
        <button
          class="option-button select-hook select-multiple ghost-option"
          type="button"
          title="ghost button"
        >
          <div class="ghost-option-description"></div>
          <div class="ghost-option-description"></div>
        </button>
      `),
      );
    }
  }
  static loadGhostThumbs(targetSelector) {
    $(targetSelector).empty();
    for (let i = 0; i < 6; i++) {
      $(targetSelector).append($(SectionLoader.ghostThumb()));
    }
  }
  static ghostThumb() {
    return `
    <div
      class="thumbnail preview-option ghost-thumb visible"
    >
      <span class="size"></span>
      <i class="fa-solid fa-image fa-2xl" style="width:100%;height:100%;"></i>
      </div>
    `;
  }
  static loadGhostPreview(targetSelector) {
    $(targetSelector)
      .empty()
      .append(
        $(`
        <div class="ghost-preview">
          <i class="fa-solid fa-image" style="width:75%;height:100%;"></i>
        </div>
    `),
      );
  }

  static loadGhostWebsiteManagement(targetSelector) {
    const left = $('<div id="website-management-left" class="col-6"></div>');
    const right = $('<div id="website-management-right" class="col-6"></div>');

    const temp = `
      <div class="irbOptions" id="ghost-irb">
        <span class="ghost-fake-icon"></span>
        <div class="content">
          <h3></h3>
          <p></p>
          <div class="row align-items-center">
            <div class="col-5">
              <button type="button" class="btn btn-dark">&nbsp;</button>
            </div>
            <div class="col-7"></div>
          </div>
        </div>
      </div>
    `;

    $(targetSelector).empty().append(left).append(right);
    left.append($(temp));
    left.append($(temp));
    left.append($(temp));
    right.append($(temp));
    right.append($(temp));
  }

  static loadGhostWebsiteData(targetSelector) {
    $(targetSelector)
      .empty()
      .append(
        $(`
        <div class="d-flex populate ghost-questions" id="tabfocus">
        <div class="container hero-create-materials" id="hero-create-materials">
          <div class="row">
            <div class="col-sm-12">

              <h1 class="color--blue hero__heading">&nbsp;</h1>

            </div>

          </div>
        </div>
      </div>
      <div id="website-data-container">
        <div class="row populate-responses" id="populate-responses">
          <div class="col-sm-12" id="website-data-input">
            <div>
              <div id="website-date-questions">
                <form id="microsite-form" class="microsite-form ghost-questions">
                  <div class="blue-box select-single">
                    <div>
                      <div class="head-and-sub-centered required">
                        <h5>&nbsp;</h5>
                        <p>&nbsp;</p>
                      </div>
                      <label for="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648" class="sr-only form-label required">Select a
                        language</label>
                      <div id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648"
                        class="card card--basic card--has-title card--multi-select">
                        <div class="option-buttons"><button class="button--option select-hook"
                            data-answer-id="2807621c-1e13-46b5-9d2f-67f02a68f9a6"
                            data-question-id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648" data-component="select-single"
                            id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648-option-0" aria-pressed="false" data-option-index="0"
                            type="button">&nbsp;</button><button class="button--option select-hook"
                            data-answer-id="94d8808d-532e-4681-940d-c51fe608d622"
                            data-question-id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648" data-component="select-single"
                            id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648-option-1" aria-pressed="false" data-option-index="1"
                            type="button">&nbsp;</button><button class="button--option select-hook"
                            data-answer-id="625731d9-6f10-4875-aa02-53588930956d"
                            data-question-id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648" data-component="select-single"
                            id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648-option-2" aria-pressed="false" data-option-index="2"
                            type="button">&nbsp;</button><button class="button--option select-hook"
                            data-answer-id="0ce56219-e7d0-47cb-b01d-5eb5be1072a9"
                            data-question-id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648" data-component="select-single"
                            id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648-option-3" aria-pressed="false" data-option-index="3"
                            type="button">&nbsp;</button><button class="button--option select-hook"
                            data-answer-id="48be8c94-1db9-4640-80b0-586731af2df4"
                            data-question-id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648" data-component="select-single"
                            id="945d40e7-2c59-4b9a-9dc8-d14d7dcdd648-option-4" aria-pressed="false" data-option-index="4"
                            type="button">&nbsp;</button></div>
                      </div>

                    </div>
                  </div>
                  <div class="blue-box text">
                    <div>
                      <div class="head-and-sub-centered required">
                        <h5>&nbsp;</h5>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p class="three">&nbsp;</p>
                      </div>

                      <div class="mb-3">
                        <label for="5e6b8c58-6fbc-4b11-af79-f2a7c8eb1ac8" class="sr-only form-label required">Enter study
                          title here...</label>
                        <input type="text" class="form-control char-limited" id="5e6b8c58-6fbc-4b11-af79-f2a7c8eb1ac8"
                          name="5e6b8c58-6fbc-4b11-af79-f2a7c8eb1ac8" required="" data-max-chars="64">
                      </div>

                    </div>
                  </div>

              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        `),
      );
  }

  static loadGhostEditor(targetSelector) {
    $(targetSelector).empty().append(`
    <div class="row skeleton-loader-container">
    <div class="container-fluid bg--blue-light step__container" id="ghost-editor" tabindex="0">
    <div class="container container--lg" id="ghost-matgen-controller">
      <div class="row justify-content-center" id="ghost-matgen-wrapper">
        <div tabindex="0" id="ghost-matgen-sidebar" class="col-sm-3 col-md-2 m4c-matgen">
          <div id="ghost-sidebar" class="user">
            <ul id="ghost-sidebar-main" class="list-group">
              <li href="#" id="ghost-sidebar-header" class="list-group-item d-flex flex-row sidebar-li">
                <div id="ghost-sidebar-title" style="background:#efefef;width:100%;">&nbsp;</div>
                <div class="sidebar-actions">
                </div>
              </li>

              <li class="list-group-item d-flex flex-row sidebar-li" id="weird-marker">
                <ul id="ghost-sidebar-tree" class="list-group" style="height: 100%;width:100%;">
                  <li tabindex="0" class="tree-li list-group-item component image" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>

                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item component image" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item component textbox" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item component textbox" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item image" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                          <a class="btn btn-outline-secondary layer-action user-toggle-object hide-btn"
                            data-bs-toggle="tooltip" data-hidden="false" style="width:7em;">
                            &nbsp;
                          </a>

                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item image" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                          <a class="btn btn-outline-secondary layer-action user-toggle-object hide-btn" href="#"
                            data-bs-toggle="tooltip" data-hidden="false" style="width:7em;">
                            &nbsp;
                          </a>

                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item component textbox" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item textbox" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item component image" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item image" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                          <a class="btn btn-outline-secondary layer-action user-toggle-object hide-btn" href="#"
                            data-bs-toggle="tooltip" data-hidden="false" style="width:7em;">
                            &nbsp;
                          </a>

                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item image" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                          <a class="btn btn-outline-secondary layer-action user-toggle-object hide-btn" href="#"
                            data-bs-toggle="tooltip" data-hidden="false" style="width:7em;">
                            &nbsp;
                          </a>

                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item component textbox" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item textbox" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item image active" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                          <a class="btn btn-outline-secondary layer-action user-toggle-object hide-btn" href="#"
                            data-bs-toggle="tooltip" data-hidden="false" style="width:7em;">
                            &nbsp;
                          </a>

                        </div>

                      </div>
                    </div>

                  </li>

                  <li tabindex="0" class="tree-li list-group-item textbox" style="user-select: none;">
                    <div class="node-content">
                      <div class="node-info">
                        <div class="node-top">

                          <div class="node-name" style="background:#ddd;">
                            &nbsp;
                          </div>


                        </div>

                      </div>
                    </div>

                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-md-8 m4c-matgen" id="ghost-nia-matgen-controller">
          <div class="editor-scaler">
            <div class="canvas-container">
              <div class="contained-canvas">
                <div class="canvas-container-0be203c6-91bb-4fe5-af4d-942e790af2c9"
                  style="width: 930px; height: 697.5px; position: relative; user-select: none;color:#dedede;text-align:center;">
                  <i class="fa-solid fa-image" style="width:75%;height:100%;"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="matgen-right-sidebar" class="col-sm-3 col-md-2">
          <div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
            <div style="background:#ddd;">&nbsp;</div>
          </div>

        </div>


      </div>
    </div>
  </div>
  </div>
  <div id="matgen"></div>
    `);
  }
}

export default SectionLoader;
